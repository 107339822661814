import "@hotwired/turbo-rails";
import * as ActiveStorage from '@rails/activestorage'
import "@/javascript/channels";
import "virtual:windi.css";
import debounced from "debounced";
debounced.initialize({ input: { wait: 400 }});

ActiveStorage.start();

import "@/view_components";
import "@/javascript/init/stimulus_reflex";
import "@/stylesheets/application.scss";