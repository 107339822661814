import "./index.scss"

import ApplicationController from "@/javascript/controllers/application_controller";

export class Controller extends ApplicationController {
	static targets = [ "input", "errorIcon", "errorMessages" ];

	removeErrors() {
		if (this.inputTarget.classList.contains("invalid")) {
			this.inputTarget.classList.replace("invalid", "valid");
			this.errorIconTarget.classList.add("hidden");
			this.errorMessagesTarget.classList.add("hidden");
		}
	}
}
