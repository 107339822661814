import { application } from "@/javascript/init/stimulus";

const modules = import.meta.glob("./**/index.js", { eager: true });
for (const path in modules) {
  const mod = modules[path];

  if (mod.Controller) {
    const identifier = path.replace(/^\.\//, '')
      .replace(/\/index\.js$/, '')
      .replace(/\//, '--')
      .replace(/_/g, '-');

    application.register(identifier, mod.Controller);
  }
}
