import "./index.scss"

import ApplicationController from "@/javascript/controllers/application_controller";

export class Controller extends ApplicationController {

  
  firstName() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(this.reflex, 3000)
  }

  reflex() {
    this.stimulate("CamperForm#first_name")
  }

  connect() {
    console.log("hi caleb")
  }

  disconnect() {
  }
}
